import * as React from 'react';
import { Link } from 'react-router-dom';

import '../css/header.css';

export const Header = () => {
	const homeLink = <Link to='/' className='nav-link pompiere'>dallora.com</Link>;
	return (
		<header className='masthead mb-auto'>
			<div className='inner'>
				<h3 className='masthead-brand pompiere'>{homeLink}</h3>
				<nav className='nav nav-masthead justify-content-center'>
					<Link to='/noi' className='nav-link pompiere'>Noi</Link>
					<Link to='/passioni' className='nav-link pompiere'>Le nostre passioni</Link>
					<a href='http://www.dallora.com/matrimonio' className='nav-link pompiere'>Il nostro Matrimonio</a>
					<Link to='/san-valentino' className='nav-link pompiere'>14/02</Link>
				</nav>
			</div>
		</header>
	);
};