import * as React from 'react';

import '../css/fonts.css';
import '../css/luca.css';

export const Luca = () => {

	return (
		<section id='luca'>
			<div className='container my-5'>
				<div className='row'>
					<div className='col black-right-border'>

						<div className='row'>
							<div className='col name-block'>
								<h1 className='kollektif-bold'>LUCA</h1>
								<h1 className='kollektif-bold'>DALL'ORA</h1>
								<p className='lead montserrat-light'>INGEGNERE MECCANICO</p>
							</div>
						</div>
						<div className='row'>
							<div className='col profile-block'>
								<h6 className='kollektif-bold'>PROFILO</h6>
								<p className='montserrat-light'>
									Sono un Ingegnere Meccanico specializzato nella realizzazione di macchinari per la lavorazione del marmo. Appassionato di problem solving, tendo a trasformare ogni sfida in una nuova opportunità di crescita!
								</p>
							</div>
						</div>
						<div className='row'>
							<div className='col skills-block'>
								<h6 className='kollektif-bold'>COMPETENZE</h6>
								<ul className='montserrat-light'>
									<li>CAD</li>
									<li>Solidworks</li>
									<li>...</li>
								</ul>
							</div>
						</div>
						<div className='row'>
							<div className='col contacts-block'>
								<h6 className='kollektif-bold'>CONTATTI</h6>
								<ul className='montserrat-light'>
									<li>e-Mail: <a href='mailto:dallora.luca@gmail.com'>dallora.luca@gmail.com</a></li>
									<li>Linkedin: <a href='https://linkedin.com/in/luca-dall-ora-4a187011b' target='_blank'>luca-dall-ora</a></li>
									<li>Website: <a href='/'>www.dallora.com</a></li>
									<li>Indirizzo: Verona</li>
								</ul>
							</div>
						</div>

					</div>
					<div className='col black-left-border'>
						<div className='container'>
							<div className='row'>
								<div className='col'>
									<h6 className='kollektif-bold title'>ESPERIENZE</h6>
									<div className='my-3'>
										<h6 className='kollektif-bold'>Ingegnere Meccanico</h6>
										<p className='kollektif'>
											Montresor &amp; Co / gen 2018 - presente
										</p>
										<p className='montserrat-light'>Responsabile dell'Ufficio Tecnico</p>
									</div>
									<div className='my-3'>
										<h6 className='kollektif-bold'>Ingegnere Meccanico - Ricerca e Sviluppo</h6>
										<p className='kollektif'>
											Gebo Cermex / ott 2016 - dic 2017
										</p>
										<p className='montserrat-light'>Nel team di Ricerca e Sviluppo per migliorare la progettazione di macchinari per la pastorizzazione e l'imbottigliamento</p>
									</div>
									<div className='my-3'>
										<h6 className='kollektif-bold'>Ingegnere Meccanico</h6>
										<p className='kollektif'>
											Donatoni Macchine Srl / nov 2011 - set 2016
										</p>
										<p className='montserrat-light'>Progettazione di macchine per la lavorazione del marmo</p>
									</div>
									<div className='my-3'>
										<h6 className='kollektif-bold'>Ingegnere Meccanico</h6>
										<p className='kollektif'>
											Scenerg Industry Spa / dic 2009 - ott 2011
										</p>
										<p className='montserrat-light'>Progettazione di impianti fotovoltaici</p>
									</div>
									<div className='my-3'>
										<h6 className='kollektif-bold'>Ingegnere Meccanico</h6>
										<p className='kollektif'>
											STULZ GmbH / set 2008 - nov 2009
										</p>
										<p className='montserrat-light'>Progettazione di impianti di condizionamento</p>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col'>
									<h6 className='kollektif-bold title'>FORMAZIONE</h6>
									<div className='my-3'>
										<h6 className='kollektif-bold'>Università degli Studi di Padova</h6>
										<p className='kollektif'>
											Laurea Specialistica in Ingegneria Meccanica /  2006 - 2009
										</p>
										<p className='montserrat-light'>Tesi di Laurea: </p>
									</div>
									<div className='my-3'>
										<h6 className='kollektif-bold'>Università degli Studi di Padova</h6>
										<p className='kollektif'>
											Laurea Triennale in Ingegneria Meccanica / 2003 - 2006
										</p>
										<p className='montserrat-light'>Tesi di Laurea: </p>
									</div>
									<div className='my-3'>
										<h6 className='kollektif-bold'>Liceo Scientifico "Messedaglia"</h6>
										<p className='kollektif'>
											Maturità Scientifica / 1998 - 2003
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};