import * as React from 'react';
import { Redirect, Link } from 'react-router-dom';

import { Auth } from '../api/auth';
import '../css/login.css';

export const Login = () => {

    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');

    const login = () => {
        Auth.login(password)
            .then(() => {
                setError('');
                setIsLoggedIn(true);
                console.log('Logged In');
            })
            .catch(err => {
                setError(err);
                console.log(err)
            });
    };

    const onChangePsw = (psw: string) => {
        setError('');
        setPassword(psw)
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            login();
        }
    };

    if (isLoggedIn) return <Redirect to='/san-valentino' />

    return (
        <section id='login'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-9 col-md-7 col-lg-5 mx-auto'>
                        <div className='card card-signin my-5'>
                            <div className='card-body'>
                                <h2 className='brusher login-tiitle'>14/02</h2>
                                <p className='card-title text-center lead kite-one'>Accedi alla Nostra area riservata</p>
                                <div className='form-signin'>
                                    <div className='form-label-group'>
                                        <input type='password' id='inputPassword' className='form-control' placeholder='Password' required value={password} onChange={(e) => onChangePsw(e.target.value)} onKeyDown={onKeyDown} />
                                    </div>
                                    <button className='btn btn-primary text-uppercase kite-one' onClick={login}>Entra</button>
                                    <p className='error red'>{error !== '' ? error : ''}</p>
                                </div>
                                <p className="back">Torna a <Link className='pompiere' to='/'>dallora.com</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};