import * as React from 'react';

import basket from '../imgs/basket-bw.jpg';
import pallavolo from '../imgs/volleyball-bw.jpg';
import sci from '../imgs/ski-bw.png';
import snowboard from '../imgs/snowboard-bw.jpg';
import fotografia from '../imgs/photographer-bw.jpg';
import viaggi from '../imgs/travel-bw.jpg';
import cucina from '../imgs/cooking-bw.jpg';
import yoga from '../imgs/yoga-bw.png';
import sub from '../imgs/dive-bw.jpg';

import '../css/passions.css';

export const Passions = () => {

	return (
		<div id='passions'>
			<div className='container'>
				<div className='row horizontal-border'>
					<div className='col-sm vertical-border'>
						<img src={basket} alt='Basket' className='luca' />
						<p className='lead'>basket</p>
					</div>
					<div className='col-sm vertical-border'>
						<img src={pallavolo} alt='Pallavolo' className='noi' />
						<p className='lead'>pallavolo</p>
					</div>
					<div className='col-sm'>
						<img src={sci} alt='sci' className='noi' />
						<p className='lead'>sci</p>
					</div>
				</div>
				<div className='row horizontal-border'>
					<div className='col-sm vertical-border'>
						<img src={snowboard} alt='snowboard' className='noi' />
						<p className='lead'>snowboard</p>
					</div>
					<div className='col-sm vertical-border'>
						<img src={fotografia} alt='fotografia' className='luca' />
						<p className='lead'>fotografia</p>
					</div>
					<div className='col-sm'>
						<img src={viaggi} alt='viaggi' className='noi' />
						<p className='lead'>viaggi</p>
					</div>
				</div>
				<div className='row'>
					<div className='col-sm vertical-border'>
						<img src={cucina} alt='cucina' className='noi' />
						<p className='lead'>cucina</p>
					</div>
					<div className='col-sm vertical-border'>
						<img src={yoga} alt='yoga' className='francesca' />
						<p className='lead'>yoga</p>
					</div>
					<div className='col-sm'>
						<img src={sub} alt='sub' className='noi' />
						<p className='lead'>subaquea</p>
					</div>
				</div>
			</div>
		</div>
	);
};