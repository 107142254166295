import * as React from 'react';
import { Link } from 'react-router-dom';

import '../css/fonts.css';
import '../css/sanValentino.css';

import love from '../imgs/love.png';
import cuore1 from '../imgs/noi-cuori/cuore-1.jpg';
import cuore2 from '../imgs/noi-cuori/cuore-2.jpg';
import cuore3 from '../imgs/noi-cuori/cuore-3.jpg';
import cuore4 from '../imgs/noi-cuori/cuore-4.jpg';

interface Props {
	children: any
}

export const SanValentino = (props: Props) => {

	return (
		<div>
			<section id='san-valentino-head'>
				<h3 className='p-3'><Link to='/' className='nav-link pompiere home-link'>dallora.com</Link></h3>
				<div className='container'>
					<div className='row'>
						<div className='col d-flex justify-content-center'>
							<Link to='/san-valentino'><img src={love} alt='love' width='150' className='love-img' /></Link>
						</div>
					</div>
					<div className='row'>
						<div className='col d-flex justify-content-center'>
							<h1 className='brusher love-title'>San Valentino</h1>
						</div>
					</div>
				</div>
			</section>
			{props.children}
			<section id='fotografie'>
				<div className="foto-container">
					<img src={cuore1} width="576" height="576" />
					<img src={cuore2} width="576" height="576" />
					<img src={cuore3} width="576" height="576" />
					<img src={cuore4} width="576" height="576" />
				</div>
			</section>
		</div>
	);
};