import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { Auth } from '../api/auth';
import { SanValentino } from './sanValentino';

interface Props extends RouteProps {
	component: any
}

export const PrivateRoute = (props: Props) => {
	const { component: Component, ...rest } = props;
	return (
		<Route
			{...rest}
			render={(routeProps) => (
				Auth.isAuthenticated === true
					? <SanValentino><Component {...routeProps} /></SanValentino>
					: <Redirect to={{
						pathname: '/login',
						state: { form: routeProps.location }
					}} />
			)}
		/>
	);
}