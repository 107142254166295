import { LoveMessage, LoginRequest } from './model';

const urlLogin = "www.dallora.com/cgi-bin/dallora/index.php";
const url = "www.dallora.com/cgi-bin/love/index.php";

export const loginRequest = (login: LoginRequest): Promise<Response> => {
	const request = new Request(`http://${urlLogin}/login`, {
		method: 'POST',
		headers: new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}),
		body: JSON.stringify(login)
	});
	return fetch(request);
};

export const saveLoveMessageRequest = (loveMsg: LoveMessage): Promise<Response> => {
	const request = new Request(`http://${url}/message`, {
		method: 'POST',
		headers: new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}),
		body: JSON.stringify(loveMsg)
	});
	return fetch(request);
};

export const getAllLoveMessageRequest = (): Promise<Response> => {
	const request = new Request(`http://${url}/messages`, {
		method: 'GET',
		headers: new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		})
	});
	return fetch(request);
};