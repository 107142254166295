import * as React from 'react';
import { Link } from 'react-router-dom';

import '../css/footer.css';

export const Footer = () => {
	return (
		<footer className='mastfoot mt-auto'>
			<div className='inner'>
				<span className='pompiere'>Credits <Link to='/francesca'>Francesca Bassi</Link></span>
			</div>
		</footer>
	);
};