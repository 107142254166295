import * as React from 'react';

import { Header } from './header';
import { Footer } from './footer';

import '../css/main.css';

interface Props {
	children: any,
	className: string
}
export const Main = (props: Props) => {

	return (
		<section className={`main text-center ${props.className}`}>
			<div className='main-container d-flex w-100 h-100 p-3 mx-auto flex-column'>
				<Header />
				{props.children}
				<Footer />
			</div>
		</section>
	);
};