import { loginRequest } from "./api";

export const Auth = {
	isAuthenticated: false,
	login(psw: string) {

		return new Promise((resolve, reject) => {
			const response = loginRequest({
				username: 'admin',
				password: psw.toLowerCase()
			});
			response
				.then((res) => {
					return res.json();
				})
				.then((json) => {
					if (json.status) {
						this.isAuthenticated = true;
						resolve(this.isAuthenticated)
					} else {
						reject('Password non corretta')
					}
				})
				.catch((error) => {
					console.log(error);
					reject('Si è verificato un errore. Riprovare più tardi.')
				})
		});

	}
};