import * as React from 'react';

import '../css/fonts.css';
import '../css/lettera.css';

import { Link } from 'react-router-dom';

export const Lettera = () => {

	return (
		<section id='lettera'>
			<div className='container my-5'>
				<div className='row'>
					<div className='col justify-content-center kite-one more'>
						<p className='align-right'>
							<small>14 Febbraio 2020</small>
						</p>
						<p>So che non festeggiamo San Valentino...</p>
						<p>So che non ti piace cadere nei cliché di questa festa ormai diventata consumistica...</p>
						<p>E un po’ sai che ti do ragione...</p>
						<p>Ma poi la vena romantica prevale e non festeggiare la festa degli innamorati mi dispiace, soprattuto perché San Valentino per noi da quest’anno potrebbe avere un significato diverso, visto che proprio nella <b>Chiesa di San Valentino</b> ci siamo sposati e ci siamo promessi amore incondizionato.</p>
						<p>Per cui in questo nuovo cammino insieme, per onorare il nostro amore in questo primo San Valentino da marito e moglie, vorrei regalarci una tradizione:</p>
						<ul>
							<li>
								<p>Visto che è il patrono del tuo paese natale e sicuramente ogni anno ci sarà la fiera di San Valentino, vorrei andassimo a messa nella nostra chiesetta per ricordarci del nostro giorno e rivivere l’emozione della messa del nostro matrimonio per non dimenticarci con gli anni le sensazioni provate.</p>
							</li>
							<li>
								<p>Scrivere un “<Link to='/ti-amo-perche' className='love-link'>Ti amo perché...</Link>” da custodire e da rileggere, magari in un momento solo nostro, a San Valentino di ogni anno per ricordare nel tempo i motivi per cui ci amiamo.</p>
							</li>
						</ul>
						<p>Quindi d’ora in poi, niente fiori, niente regali, niente “spreco” di soldi, ma solo un po’ di tempo per noi, perché il tempo alla fine è il bene più prezioso che abbiamo.</p>
						<p>Spero che l’iniziativa ti piaccia… che accoglierai con entusiasmo il mio regalo e che questa possa essere una delle tradizioni di famiglia da mantenere e coltivare.</p>
						<p className="brusher moreMore align-center">Ti amo Luca, Marito Mio</p>
						<p className='align-right'>Per sempre <br /> Tua Moglie, Francesca</p>
					</div>
				</div>
			</div>
		</section>
	);
}