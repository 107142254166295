import * as React from 'react';
import { Link } from 'react-router-dom';

import fra from '../imgs/fra-bw.jpg';
import luca from '../imgs/luca-bw.jpg';
import '../css/aboutUs.css';

export const AboutUs = () => {

	return (
		<div className='us m-auto'>
			<div className='container'>
				<div className='row'>
					<div className='col border-separation px-5 my-5'>
						<img src={fra} alt='Francesca' className='rounded-circle' width='300' height='300' />
						<h1 className='my-5'>Francesca</h1>
						<p className='lead my-5'>Ing. Informatico con la passione per la buona cucina e telefilm dipendente</p>
						<Link to='/francesca' target='_blank'>Per saperne di più...</Link>
					</div>
					<div className='col px-5 my-5'>
						<img src={luca} alt='Luca' className='rounded-circle' width='300' height='300' />
						<h1 className='my-5'>Luca</h1>
						<p className='lead my-5'>Ing. Meccanico sempre in movimento e orientato all'ottimismo</p>
						<Link to='/luca' target='_blank'>Per saperne di più...</Link>
					</div>
				</div>
			</div>
		</div>
	);
};