import * as React from 'react';

import '../css/fonts.css';
import '../css/tiAmoPerche.css';
import { Love } from 'src/api/love';
import { LoveMessage } from 'src/api/model';
import { LoveMessagesItems } from './loveMessagesItems';
import { Modal } from './modal';

import luca from '../imgs/luca.jpg';
import francesca from '../imgs/fra.jpg';

export const TiAmoPerche = () => {

	const [loveMessages, setLoveMessages] = React.useState();
	const [error, setError] = React.useState('');
	const [modalError, setModalError] = React.useState('');
	const [loveMsg, setLoveMsg] = React.useState('');
	const [show, setShow] = React.useState(false);
	const [selectedOption, setSelectedOption] = React.useState('');
	const [disabled, setDisabled] = React.useState(true);

	const showModal = () => {
		setShow(true);
	};

	const hideModal = () => {
		setLoveMsg('');
		setSelectedOption('');
		setShow(false);
	};

	const saveLoveMsg = () => {
		setModalError('');
		if (selectedOption !== '' && loveMsg !== '') {
			const d = new Date();
			setModalError('');
			Love.saveLoveMessage({
				messaggio: escape(loveMsg),
				autore: selectedOption,
				quando: d.toISOString().split('T')[0]
			}).then((r: any) => {
				console.log('Risposta save: ', r);
				hideModal();
				Love.getAllLoveMessages()
					.then((m: LoveMessage[]) => {
						setError('');
						setLoveMessages(m);
					})
					.catch(err => {
						setError(err);
					});
			}).catch(err => {
				setModalError(err);
			});
		} else {
			setModalError('Dovresti selezionare chi sei ;-)');
		}

	}

	React.useEffect(() => {
		Love.getAllLoveMessages()
			.then((m: LoveMessage[]) => {
				setError('');
				setLoveMessages(m);
			})
			.catch(err => {
				setError(err);
			});
	}, []);

	const onChangeText = (t: string) => {
		if (t !== '') {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
		setLoveMsg(t);
	}

	const handleOptionChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
		setModalError('');
		setSelectedOption(changeEvent.target.value);
	}


	return (

		<section id='ti-amo-perche'>
			<div className='container my-5'>
				<div className='row'>
					<div className='col justify-content-center'>
						<h1 className='brusher tiamo-title'>
							Ti amo perche'...
							<button type='button' className='tiamo-button btn btn-default' onClick={showModal}>
								<i className='fas fa-heart fa-3x'></i>
							</button>
							<Modal show={show} handleClose={hideModal} handleSave={saveLoveMsg} disabled={disabled} error={modalError}>
								<h1>... chi sei ?</h1>
								<div>
									<input type='radio' name='author' id='luca' className='input-hidden' checked={selectedOption === 'Luca'} onChange={handleOptionChange} value='Luca' />
									<label htmlFor='luca'><img src={luca} alt='Luca' className='img-circle' /></label>
									<input type='radio' name='author' id='francesca' className='input-hidden' checked={selectedOption === 'Francesca'} onChange={handleOptionChange} value='Francesca' />
									<label htmlFor='francesca'><img src={francesca} alt='Francesca' className='img-circle' /></label>
								</div>
								<div>
									<textarea placeholder='Ti amo perché...' wrap='off' id='message-area' rows={20} required value={loveMsg} onChange={(e) => onChangeText(e.target.value)} />
								</div>

							</Modal>
						</h1>

					</div>
				</div>
				<div className='row'>
					<div className='col justify-content-center kite-one more'>
						<LoveMessagesItems messages={loveMessages || []} />
						<p className='error red'>{error !== '' ? error : ''}</p>
					</div>
				</div>
			</div>

		</section>

	);
};