import * as React from 'react';

import { LoveMessage, Author } from 'src/api/model';

import '../css/loveMessagesItems.css';

interface Props {
	messages: LoveMessage[];
}
export const LoveMessagesItems = (props: Props) => {

	const element = props.messages.map((c, i) => {
		let align = '';
		if (c.autore === Author.francesca) {
			align = 'align-right';
		} else if (c.autore === Author.luca) {
			align = 'align-left';
		}
		return (
			<div key={`msg-${i}`} className='love-div'>
				<span className={`love-items message ${align} ${c.autore}`}>...{unescape(c.messaggio)}</span>
				<span className={`love-items author-date ${align}`}>- {c.autore}, {c.quando}</span>
			</div>
		);
	});

	return (

		<div id='love-messagges-items'>
			{element}
		</div>

	);
};