import { LoveMessage } from './model';
import { saveLoveMessageRequest, getAllLoveMessageRequest } from './api';

export const Love = {
	getAllLoveMessages() {
		return new Promise((resolve, reject) => {
			const response = getAllLoveMessageRequest();
			response
				.then((res) => {
					return res.json();
				})
				.then((json) => {
					if (json.status) {
						resolve(json.data)
					} else {
						reject(json.message)
					}
				})
				.catch((error) => {
					reject(`Si è verificato un errore. Riprovare più tardi. ${error}`)
				})
		});
	},
	saveLoveMessage(loveMsg: LoveMessage) {
		return new Promise((resolve, reject) => {
			const response = saveLoveMessageRequest(loveMsg);
			response
				.then((res) => {
					return res.json();
				})
				.then((json) => {
					if (json.status) {
						resolve(json)
					} else {
						reject(`Errore: ${json.message}`)
					}
				})
				.catch((error) => {
					reject(`Si è verificato un errore. Riprovare più tardi. ${error}`)
				})
		});
	}
};