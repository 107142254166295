import * as React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import { Welcome } from './welcome';
import { AboutUs } from './aboutUs';
import { Main } from './main';
import { Francesca } from './francesca';
import { Luca } from './luca';
import { Passions } from './passions';
import { Login } from './login';
import { PrivateRoute } from './privateRoute';
import { Lettera } from './lettera';
import { TiAmoPerche } from './tiAmoPerche';

export const Routes = () => {

    return (
        <Router>
            <Switch>
                <Route path='/' exact={true} render={() => <Main className='welcome'><Welcome /></Main>} />
                <Route path='/noi' render={() => <Main className='about-us'><AboutUs /></Main>} />
                <Route path='/passioni' render={() => <Main className='passions'><Passions /></Main>} />
                <Route path='/francesca' component={Francesca} />
                <Route path='/luca' component={Luca} />
                <Route path='/login' component={Login} />
                <PrivateRoute path="/san-valentino" component={Lettera} />
                <PrivateRoute path="/ti-amo-perche" component={TiAmoPerche} />
            </Switch>
        </Router>
    );
}