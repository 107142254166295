export interface LoginRequest {
	username: string;
	password: string;
}

export interface LoveMessage {
	messaggio: string;
	autore: string;
	quando: string;
}

export const Author = {
	francesca: 'Francesca',
	luca: 'Luca'
}
