import * as React from 'react';

import '../css/fonts.css';
import '../css/francesca.css';

export const Francesca = () => {

	return (
		<section id='francesca'>
			<div className='container my-5'>
				<div className='row'>
					<div className='col d-flex justify-content-center'>
						<div className='circle-div'>
							<div className='text-center name-content d-flex justify-content-center align-items-center'>
								<div>
									<h1 className='playlist-script display-4'>Francesca</h1>
									<h1 className='playlist-script display-4'>Bassi</h1>
									<h5 className='glacial-indifference-bold'>FULL STACK</h5>
									<h5 className='glacial-indifference-bold'>WEB DEVELOPER</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row' id='curriculum'>
					<div className='col pink-right-border'>
						<div className='container py-5'>
							<div className='row my-5'>
								<div className='col d-flex align-items-end flex-column left'>
									<h6 className='glacial-indifference-bold pink'>PROFILO</h6>
									<p className='glacial-indifference text-right'>
										Sono un Ingegnere Informatico con la passione per tutto quello che riguarda il mondo del Web sia lato frontend, come siti internet o applicazioni mobile, sia lato backend.<br />Sono sempre desiderosa di imparare, migliorarmi e affrontare nuove sfide.
									</p>
								</div>
							</div>
							<div className='row my-5'>
								<div className='col d-flex align-items-end flex-column left '>
									<h6 className='glacial-indifference-bold pink'>COMPETENZE</h6>
									<ul className='glacial-indifference text-right'>
										<li>Javascript, React, HTML, CSS</li>
										<li>Android e iOS Development</li>
										<li>PHP, NodeJs, Scala</li>
										<li>MySQL, PostgreSQL</li>
										<li>Adobe Photoshop, InDesign, XD, Illustrator</li>
									</ul>
								</div>
							</div>
							<div className='row my-5'>
								<div className='col d-flex align-items-end flex-column left'>
									<h6 className='glacial-indifference-bold pink'>CONTATTI</h6>
									<ul className='glacial-indifference text-right'>
										<li>Mobile: +39 335 105 61 36</li>
										<li>e-Mail: <a href='mailto:francesca.bassi.84@gmail.com'>francesca.bassi.84@gmail.com</a></li>
										<li>Linkedin: <a href='https://www.linkedin.com/in/francescabassi84' target='_blank'>francescabassi84</a></li>
										<li>Website: <a href='/'>www.dallora.com</a></li>
										<li>Indirizzo: Verona</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className='col pink-left-border'>
						<div className='container py-5'>
							<div className='row my-5'>
								<div className='col d-flex align-items-start flex-column'>
									<h6 className='glacial-indifference-bold pink'>ESPERIENZE</h6>
									<div className='text-left my-2'>
										<h6 className='glacial-indifference-bold'>Fullstack Developer</h6>
										<p className='glacial-indifference-italic'>
											UniCredit / gen 2014 - presente
										</p>
										<p className='glacial-indifference'>
											Progettazione e sviluppo di progetti innovativi all'interno del dipartimento di Ricerca e Sviluppo.
										</p>
									</div>
									<div className='text-left my-2'>
										<h6 className='glacial-indifference-bold'>IT Analysis and Programming Professional</h6>
										<p className='glacial-indifference-italic'>
											UniCredit Business Integrated Solution / lug 2013 - dic 2013
										</p>
										<p className='glacial-indifference'>
											Progettazione e sviluppo di prototipi di applicazioni mobile all'interno del dipartimento di Innovazione.
										</p>
									</div>
									<div className='text-left my-2'>
										<h6 className='glacial-indifference-bold'>Web Developer</h6>
										<p className='glacial-indifference-italic'>
											Gruppo Mediarete Srl / mar 2012 - lug 2013
										</p>
										<p className='glacial-indifference'>
											Creazione e sviluppo di siti web, newsletter, landing page per i clienti dell'azienda.
										</p>
									</div>
									<div className='text-left my-2'>
										<h6 className='glacial-indifference-bold'>Student</h6>
										<p className='glacial-indifference-italic'>
											Università degli Studi di Parma / ott 2003 - ott 2011
										</p>
									</div>
								</div>
							</div>
							<div className='row my-5'>
								<div className='col d-flex align-items-start flex-column'>
									<h6 className='glacial-indifference-bold pink'>FORMAZIONE</h6>
									<div className='text-left my-2'>
										<h6 className='glacial-indifference-bold'>Università degli Studi di Parma</h6>
										<p className='glacial-indifference-italic'>
											Laurea Specialistica in Ingegneria Informatica / 2008 - 2011
										</p>
										<p className='glacial-indifference'>
											Tesi di Laurea: Una piattaforma mobile reputation-based per la segnalazione georeferenziata di situazioni di interesse sociale.
										</p>
									</div>
									<div className='text-left my-2'>
										<h6 className='glacial-indifference-bold'>Università degli Studi di Parma</h6>
										<p className='glacial-indifference-italic'>
											Laurea Triennale in Ingegneria Informatica / 2003 - 2007
										</p>
										<p className='glacial-indifference'>
											Tesi di Laurea: Produzione di documenti in formato WordML mediante l'elaborazione di template.
										</p>
									</div>
									<div className='text-left my-2'>
										<h6 className='glacial-indifference-bold'>Liceo Scientifico "Primo Levi"</h6>
										<p className='glacial-indifference-italic'>
											Maturità Scientifica / 1998 - 2003
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};