import * as React from 'react';

import '../css/modal.css';

interface Props {
	handleClose: any;
	handleSave: any;
	show: boolean;
	children: any;
	disabled: boolean;
	error: string;
}
export const Modal = (props: Props) => {

	const showHideClassName = props.show ? "kite-one modal display-block" : "kite-one modal display-none";

	return (
		<div className={showHideClassName}>
			<section className="modal-main">
				{props.children}
				<button type="button" className="btn btn-primary close-button" onClick={props.handleClose}>Chiudi</button>
				<button type="button" className="btn btn-primary save-button" onClick={props.handleSave} disabled={props.disabled}>Salva</button>
				<p className='error red'>{props.error}</p>
			</section>
		</div>
	);
};