import * as React from 'react';

import '../css/welcome.css';

export const Welcome = () => {

	return (
		<main className='inner cover'>
			<h1 className='cover-heading pompiere animate-pop-in'>Famiglia Dall'Ora</h1>
			<p className='lead pompiere animate-pop-in'>noi. la nostra vita. le nostre passioni.</p>
		</main>
	);
};